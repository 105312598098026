<template>
    <div class="page page--interior">
        <LookNFeel />     
        <LookNFeelWhite />   
        <div class="animateScroll bgr-img" v-observe-visibility="(isVisible, entry) => visibilityChanged(isVisible, entry, 'fade-up')">
            <img src="@/assets/images/bgr/interior-big-2-mobil.jpg"  />
        </div>
    </div>
</template>


<script>
import LookNFeel from '@/components/pages/LookNFeel.vue'
import LookNFeelWhite from '@/components/pages/LookNFeelWhite.vue'

export default {    
  components: {    
    LookNFeel,
    LookNFeelWhite    
  }  
}
</script>