<template>
  <div class="LookNFeelWhite">
      <b-container fluid="xl">
          <b-row class="align-self-stretch">              
                <b-col cols="12" xl="6" class="d-flex flex-column justify-content-between">
                    <h2 class="animateScroll" v-observe-visibility="(isVisible, entry) => visibilityChanged(isVisible, entry, 'fade-up')" v-html="tr('look_n_feel_white_title')">
                    </h2>
                    <p class="LookNFeelWhite__text">{{ tr('look_n_feel_white_text')}}</p>
                    <img src="/pictures/looknfeelwhite/look-n-feel-01.jpg" class="w-100 animateScroll" v-observe-visibility="(isVisible, entry) => visibilityChanged(isVisible, entry, 'fade-up')"/>
                </b-col>              
                <b-col cols="12" xl="6">
                    <img src="/pictures/looknfeelwhite/look-n-feel-03.jpg" class="w-100 animateScroll" v-observe-visibility="(isVisible, entry) => visibilityChanged(isVisible, entry, 'fade-up')"/>    
                </b-col>              
          </b-row>
          <b-row>
              <b-col cols="12" xl="5">
                   <img src="/pictures/looknfeelwhite/look-n-feel-04.jpg" class="w-100 animateScroll" v-observe-visibility="(isVisible, entry) => visibilityChanged(isVisible, entry, 'fade-up')"/>
              </b-col>
              <b-col cols="12" xl="7">
                    <img src="/pictures/looknfeelwhite/look-n-feel-02.jpg" class="w-100 animateScroll" v-observe-visibility="(isVisible, entry) => visibilityChanged(isVisible, entry, 'fade-up')"/>
              </b-col>
          </b-row>
          <b-row>
              <b-col cols="12">
                   <img src="/pictures/looknfeelwhite/look-n-feel-05.jpg" class="w-100 animateScroll" v-observe-visibility="(isVisible, entry) => visibilityChanged(isVisible, entry, 'fade-up')"/>
              </b-col>       
          </b-row>
      </b-container>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>